@mixin for-desktop-small {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin for-tablet-big {
  @media (max-width: 990px) {
    @content;
  }
}

@mixin for-tablet {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin for-phone {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin for-phone-small {
  @media (max-width: 385px) {
    @content;
  }
}
