.home {
  width: $content-block;
  margin: auto;
  padding: 0 20px;

  @include for-tablet-big {
    width: 100%;
  }

  &__header {
    line-height: 115%;
  }

  &__data {
    display: flex;
    gap: 23px;

    @include for-tablet-big {
      flex-direction: column-reverse;
      gap: unset;
    }
  }

  &__title {
    font-size: 30px;
    line-height: 41px;

    @include for-tablet {
      font-size: 20px;
      line-height: 27px;

      br {
        display: none;
      }
    }

    @include for-phone {
      br {
        display: block;
      }
    }

    &-earn {
      color: $slate-purple;
    }
  }

  &__prize {
    margin-top: 52px;

    @include for-phone {
      margin-top: 42px;
    }
  }

  &__sign-up-text {
    margin-top: 49px;
    font-size: 24px;
    line-height: 33px;

    @include for-tablet {
      font-size: 20px;
      line-height: 27px;
      margin-top: 45px;
    }
  }

  &__statistic {
    font-size: 18px;
    line-height: 25px;
    margin-top: 56px;

    @include for-tablet {
      font-size: 20px;
      margin-top: 65px;
    }

    &-status {
      background: inherit;
      font-size: inherit;
      color: $blue;
      font-weight: 600;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
