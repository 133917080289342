.share-social {
  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 21px;

    @include for-phone {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
