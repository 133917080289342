.footer {
  font-weight: 600;
  line-height: 150%;
  min-width: $left-block-width;

  @include for-tablet-big {
    margin-top: 40px;
  }

  @include for-tablet {
    font-size: 12px;
    width: 100%;
    min-width: unset;
  }

  &__text {
    margin-bottom: 1.7rem;
    max-width: 216px;

    @include for-tablet {
      margin-bottom: 1rem;
      max-width: 238px;
    }
  }

  &__time {
    display: block;
  }

  &__link {
    color: $blue;
    transition: 0.2s all;

    &:hover {
      cursor: pointer;
    }
  }

  &__logo {
    margin-top: 57px;
    display: inline-block;

    @include for-tablet {
      margin-top: 49px;
    }
  }
}
