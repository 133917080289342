.sign-in {
  width: $content-block;
  margin: auto;
  padding: 0 20px;
  position: relative;

  @include for-tablet-big {
    width: 100%;
  }

  &__data {
    display: flex;
    gap: 23px;

    @include for-tablet-big {
      flex-direction: column-reverse;
      gap: unset;
    }

    @include for-phone {
      padding-top: 11px;
    }
  }

  &__footer {
    margin-top: 70px;

    @include for-tablet-big {
      margin-top: 40px;
    }

    @include for-phone {
      margin-top: 16px;
    }
  }

  &__loader {
    opacity: 1;
    pointer-events: none;
  }
}
