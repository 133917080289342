* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: unset;
  font-weight: unset;
}

html {
  font-size: 14px;
  color: $black;
  font-weight: 500;
  font-family: 'Manrope', sans-serif;
}

li {
  list-style-type: none;
}

a {
  &:hover {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:link {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }
}

button,
input {
  border: none;
  outline: none;
  font-family: inherit;
}

p {
  margin: 0;
}
