.prize {
  margin-top: 51px;
  position: relative;

  @include for-tablet {
    margin-top: 41px;
  }

  @include for-phone {
    margin-top: 27px;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $lilac;
  }

  &__list {
    margin-top: 20px;
  }

  &__background {
    position: absolute;
    left: -100px;
    top: -150px;
    z-index: -1;
  }
}
