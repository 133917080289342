.sign-form {
  margin-top: 24px;
  max-width: 418px;
  min-width: fit-content;
  height: 57px;
  background: $white;
  box-shadow: 0px 0px 15px rgba(107, 110, 249, 0.25);
  border-radius: 100px;
  display: flex;

  @include for-phone {
    width: 100%;
    height: 53px;
    margin-top: 18px;
  }

  &__input {
    flex: 1 1 100%;
    padding-left: 30px;
    font-size: 20px;
    padding-right: 10px;
    border-radius: 100px;

    @include for-tablet {
      font-size: 18px;
      width: 100%;
    }

    @include for-phone-small {
      padding-left: 15px;
      padding-right: 5px;
    }

    &::placeholder {
      font-size: 20px;
      line-height: 27px;
      color: $gray;

      @include for-tablet {
        font-size: 18px;
      }
    }
  }

  &__error {
    position: relative;

    &::after {
      opacity: 1;
      content: '';
      display: block;
      position: absolute;
      border-radius: 100px;
      height: 96%;
      width: 99%;
      min-width: inherit;
      border: 2px solid red;
      z-index: 5;
      pointer-events: none;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 10px 30px 14px;
    border-radius: 100px;
    background: linear-gradient(138.93deg, $blue 22.36%, $slate-purple 76.72%);
    font-size: 24px;
    line-height: 33px;
    color: $white;
    width: 80px;
    min-width: fit-content;
    transition: all 1s;

    @include for-tablet {
      font-size: 18px;
    }

    @include for-phone-small {
      padding: 10px 15px 14px;
    }

    &:hover {
      cursor: pointer;
      background: linear-gradient(45deg, $blue 22.36%, $slate-purple 76.72%);
    }
  }
}
