.referr-statistic {
  position: relative;

  @include for-tablet-big {
    margin-top: 40px;
  }

  @include for-phone {
    margin-top: unset;
    position: absolute;
    top: 31px;
    right: 38px;
  }

  @include for-phone-small {
    top: 15px;
    right: 15px;
  }

  &__title {
    font-size: 20px;
    line-height: 27px;

    @include for-phone {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__count {
    padding: 20px 15px;
    background: #ffffff;
    border-radius: 8px;
    color: $black-h1;
    font-size: 36px;
    line-height: 49px;
    width: fit-content;
    margin-top: 24px;

    @include for-phone {
      font-size: 18px;
      line-height: 25px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      margin-top: 4px;
    }

    &-color {
      display: inline-block;
      margin-right: 5px;
      font-weight: 600;
      font-size: 50px;
      line-height: 68px;
      background: linear-gradient(180deg, #a463b0 0%, #6b6ef9 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      color: #6b6ef9;

      @include for-phone {
        font-size: 24px;
        line-height: 33px;
        margin-right: 8px;
      }
    }
  }

  &__text {
    font-weight: 600;
    line-height: 150%;
    margin-top: 13px;

    @include for-phone {
      margin-top: 6px;
      font-size: 12px;
      line-height: 16px;
    }

    &-color {
      color: $lilac;
    }
  }

  &__background {
    position: absolute;
    z-index: -1;
    left: -120px;
    bottom: -140px;

    @include for-phone {
      left: -200px;
    }
  }
}
