.share-social-list {
  display: flex;
  gap: 24px;

  svg {
    transition: all 0.1s;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  @include for-phone {
    flex-wrap: wrap;
    gap: 18px;

    svg {
      width: 30px;
      height: auto;
    }
  }
}
