.share-input {
  &__title {
    font-size: 24px;
    line-height: 33px;

    @include for-phone {
      font-size: 20px;
      line-height: 27px;
    }
  }

  &__link {
    margin-top: 26px;
    display: flex;
    position: relative;
    align-items: center;
    max-width: 418px;
    height: 47px;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(107, 110, 249, 0.25);
    border-radius: 100px;
    overflow: hidden;

    @include for-phone {
      height: 53px;
    }

    &-wrapper {
      position: relative;
      max-width: fit-content;
    }

    &-text {
      flex: 1 1 100%;
      line-height: 19px;
      padding-left: 30px;
      margin-right: 10px;
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include for-phone-small {
        padding-left: 15px;
      }
    }

    &-copy {
      position: absolute;
      right: -28px;
      top: 0;
      font-size: 12px;
      line-height: 1.3em;
      width: 80px;
      border-radius: 100px;
      transform: translateX(-50%);
      padding: 4px;
      color: #ffffff;
      opacity: 0;
      pointer-events: none;
      display: flex;
      justify-content: center;
      background: linear-gradient(138.93deg, #5f8afa 22.36%, #6b6ef9 76.72%);

      @include for-phone-small {
        width: 70px;
      }
    }

    &-animation {
      animation: ease-in-out 1s 1 running slidein;
    }

    &-btn {
      height: 100%;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: #ffffff;
      background: linear-gradient(138.93deg, #5f8afa 22.36%, #6b6ef9 76.72%);
      border-radius: 100px;
      padding: 10px 30px;

      &:hover {
        cursor: pointer;
        background: linear-gradient(45deg, #5f8afa 22.36%, #6b6ef9 76.72%);
      }

      @include for-tablet {
        font-size: 18px;
      }

      @include for-phone-small {
        padding: 10px 20px 14px;
      }
    }
  }
}

@keyframes slidein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    top: -30px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -30px;
  }
}
