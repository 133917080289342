.prize-list {
  display: flex;
  gap: 20px;

  @include for-desktop-small {
    flex-wrap: wrap;
  }

  @include for-phone {
    flex-wrap: nowrap;
    overflow-y: hidden;
    width: 100vw;
    margin-left: -20px;
    padding-left: 20px;
    padding-bottom: 10px;
  }

  &__item {
    background-color: $white;
    padding: 11px 15px 12px;
    border-radius: 8px;
    min-width: 216px;
    width: 216px;
  }

  &__amount {
    font-weight: 600;
    font-size: 50px;
    line-height: 68px;
    background: linear-gradient(180deg, #a463b0 0%, #6b6ef9 100%);
    overflow: hidden;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: $slate-purple;
  }

  &__text {
    line-height: 19px;
    margin-top: 3px;

    @include for-phone {
      margin-top: 1px;
    }
  }
}
