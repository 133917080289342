// Colors HEX
$white: #ffffff;

$black: #262626;
$black-h1: #3f4246;
$black-body: #676767;
$gray: #9d9fa1;
$gray-02: #a7a7a7;
$gray-03: #d1d1d1;
$light-gray: #f5f5f5;
$background: #fcfcfc;

$light-green: #aad055;
$yellow: #ffc860;
$orange: #e3935b;
$turquoise: #4fd1d9;
$blue: #5f8afa;
$slate-purple: #6b6ef9;
$lilac: #a463b0;
$red: #db5555;

$content-block: 990px;
$left-block-width: 325px;
