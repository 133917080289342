.header {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 95px 0 53px;
  width: 970px;
  margin: auto;

  @include for-desktop-small {
    padding-top: 70px;
  }

  @include for-tablet-big {
    padding: 95px 0 53px;
    gap: 10px;
    width: unset;
    margin: unset;
  }

  @include for-phone {
    gap: 17px;
    flex-wrap: wrap;
    padding: 10px 0 24px;
    align-items: unset;
    line-height: 41px;
  }

  &__text {
    min-width: 630px;
    color: $black-h1;
    font-size: 48px;
    line-height: 115%;

    @include for-desktop-small {
      min-width: unset;
    }

    @include for-phone {
      line-height: 41px;
    }

    &-color {
      color: $lilac;

      @include for-phone {
        display: block;
      }
    }

    &-login {
      @include for-phone {
        display: unset;
      }
    }

    @include for-tablet-big {
      font-size: 30px;
    }

    @include for-phone-small {
      font-size: 24px;
    }
  }
}
